import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading, Paragraph, Button, OverlayProvider, Modal, SimpleGrid as Grid } from 'r10-source-library';

import Liveness, { logout } from '../../services/Liveness';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';

const Div = styled.div`
  text-align: center;
`;

function calcuateTimeLeft(millisecondsRemaining) {
  const minutes = Math.floor(
    (millisecondsRemaining % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((millisecondsRemaining % (1000 * 60)) / 1000);
  const minutesString = minutes === 1 ? 'minute' : 'minutes';
  const secondsString = seconds === 1 ? 'second' : 'seconds';
  if (millisecondsRemaining < 0) {
    Liveness.checkTimeout();
    return `0 ${minutesString} 0 ${secondsString}`;
  }
  return `${minutes} ${minutesString} ${seconds} ${secondsString}`;
}

const InactivityWarning = () => {
  const timeLeftString = calcuateTimeLeft(
    Liveness.screenLockTimeout - Liveness.getInactivityDuration()
  );
  const [timeLeft, setTimeLeft] = useState(timeLeftString);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTimeLeft(
        calcuateTimeLeft(
          Liveness.screenLockTimeout - Liveness.getInactivityDuration()
        )
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);
  return (
    <OverlayProvider>
      <Modal
        srName="test-modal"
        isOpen
        size={4}
        animate
        isClosable
        onCloseCb={() => {
          Liveness.update(true);
        }}
      >
        <Grid
          columns={{ sm: 1 }}
          justify="center"
          spacing={3}
          verticalAlign="center"
        >
          <Div>
            <ClockIcon />
          </Div>
          <Heading
            size={1}
            level={5}
            justify="center"
            weight={3}
            text="Session time-out"
          />
          <Paragraph justify="center" size={2}>
            Your session will expire and automatically log out in
          </Paragraph>
          <Paragraph justify="center" weight={3} size={2} bold>
            {timeLeft}
          </Paragraph>
          <Paragraph justify="center" size={2}>
            Press continue to continue your session, or log out to end.
          </Paragraph>
          <Grid
            columns={{ sm: 2 }}
            spacing={3}
            justify="center"
            layout={{ sm: [12], lg: [3] }}
          >
            <Div />
            <Div>
              <Button
                text="Continue"
                appearance="primary"
                onClick={() => Liveness.update(true)}
              />
            </Div>
            <Div>
              <Button
                text="Log out"
                appearance="alt1"
                onClick={() => logout()}
              />
            </Div>
            <Div />
          </Grid>
        </Grid>
      </Modal>
    </OverlayProvider>
  );
};

export default InactivityWarning;
