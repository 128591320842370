import { render, registry } from './pages/renderer';
import { shallowEqual, useSelector } from 'react-redux';
import GenericTemplatedPage from './components/GenericTemplatedPage';

registry.GenericPage = GenericTemplatedPage;

const SharedPages = () => {
  console.log(`Rendering`);
  const sharedPagesJson = useSelector(
    store => store.pages.sharedPages,
    shallowEqual
  );

  const rendered = render(sharedPagesJson);
  console.log(rendered);
  return rendered;
};

export default SharedPages;
