import {
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_FAIL,
  GLOBAL_LOADING
} from 'actions/configActions';

const initialState = {
  data: null,
  error: null,
  isLoading: false
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case CONFIG_REQUEST_SUCCESS:
      localStorage.setItem('config', JSON.stringify(payload));
      return {
        ...state,
        data: payload
      };
    case CONFIG_REQUEST_FAIL:
      return {
        ...state,
        data: null,
        error
      };

    case GLOBAL_LOADING:
      return {
        ...state,
        isLoading: payload ?? !state.isLoading
      };
    default:
      return state;
  }
};
