/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash.isempty";
import styled, { css } from "styled-components";
import {
  Paragraph,
  SimpleGrid as Grid,
  Heading as Typography,
  Icon,
  ListGroup,
  ListItem,
  MatchMedia,
  Divider,
  Image,
  Mixins,
} from 'r10-source-library';

import Vodafone from "../../assets/vodafone.svg";
import Menu from "../../assets/menu.svg";
import Sidebar from "../Sidebar";
import { changeLanguage } from "../../actions/languageActions";
import Name from "../Name";
import InactivityWarning from "../dialogs/InactivityWarning";

const AppBar = styled.div(
  (props) => css`
    background-color: ${props.theme.color.primary2.default};
    color: inherit;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    width: 100%;
    display: flex;
    z-index: 10;
    flex-shrink: 0;
    flex-direction: column;
  `
);

const ToolBar = styled.div`
  position: relative;
  min-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
`;

const IconButton = styled.a(
  (props) => css`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: background-color 0.15s;
    &:hover {
      background-color: ${props.theme.color.monochrome2.default};
    }
  `
);

const Badge = styled.span(
  (props) => css`
    position: absolute;
    top: 14%;
    right: 14%;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: ${props.theme.color.monochrome1.default};
    background-color: ${props.theme.color.secondary1.default};
  `
);

const Dropdown = styled.div(
  (props) => css`
    display: none;
    position: absolute;
    top: 100%;
    background-color: ${props.theme.color.monochrome1.default};
    min-width: 16px;
    ${Mixins.elevation(true)};
    padding: 12px 16px;
    z-index: 2;
  `
);

const Header = ({ authentication, language, changeAppLanguage }) => {
  const [openFlyout, setOpenFlyout] = useState(false);
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const currentLanguage = useSelector((state) => state.language.language);
  const [lang, setLang] = useState(currentLanguage?.toUpperCase() || "EN");

  const handleClickOutside = (event) => {
    if (containerEl.current && !containerEl.current.contains(event.target)) {
      inputEl.current.style.display = "none";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (currentLanguage) setLang(currentLanguage?.toUpperCase() || "EN");
  }, [currentLanguage]);

  const handleClick = () => {
    inputEl.current.style.display =
      inputEl.current.style.display === "block" ? "none" : "block";
  };

  return (
    <>
      <AppBar className="header">
        <ToolBar>
          <Grid columns={{ sm: 2 }} layout={{ sm: [4, 8], md: [6] }}>
            <Grid justify="left" verticalAlign="center">
              <IconButton
                onClick={() => setOpenFlyout(!openFlyout)}
                aria-label="menu"
              >
                <Image
                  alt="Menu"
                  sm={{
                    src: Menu,
                    width: 24,
                  }}
                />
              </IconButton>
              <div style={{ margin: "0 16px 0 8px" }}>
                <Image
                  alt="vodafone logo"
                  sm={{
                    src: Vodafone,
                    width: 24,
                  }}
                />
              </div>
              <MatchMedia breakpoint="md" andAbove>
                <Typography
                  size={1}
                  level={5}
                  text={`Vodafone${
                    authentication.ctry ? `\u00A0${authentication.ctry}` : ""
                  }`}
                />
              </MatchMedia>
            </Grid>
            <Grid justify="right" verticalAlign="center">
              <IconButton>
                <Icon name="search" size={2} />
              </IconButton>
              <IconButton>
                <Icon name="notification" size={2} />
              </IconButton>
              <IconButton
                onClick={
                  Object.keys(language?.languages || {}).length > 1
                    ? handleClick
                    : null
                }
                ref={containerEl}
              >
                <Icon name="international" size={2} />
                <Badge>
                  <Paragraph size={1} justify="center" inverse>
                    {lang}
                  </Paragraph>
                </Badge>
                <Dropdown ref={inputEl}>
                  <ListGroup>
                    {language.languages &&
                      Object.entries(language.languages).map((entry) => (
                        <>
                          <ListItem
                            onClick={() => {
                              setLang(entry[0].toUpperCase());
                              changeAppLanguage(entry[0]);
                            }}
                          >
                            {entry[1].name}
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                  </ListGroup>
                </Dropdown>
              </IconButton>
              {!isEmpty(authentication) && <Name />}
            </Grid>
          </Grid>
        </ToolBar>
        {authentication.inactivityWarningShown && <InactivityWarning />}
      </AppBar>
      <Sidebar openFlyout={openFlyout} setOpenFlyout={setOpenFlyout} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeAppLanguage(language) {
    dispatch(changeLanguage(language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
