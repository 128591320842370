/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { OverlayProvider, Modal, Link, Divider } from 'r10-source-library';
import { useHistory } from "react-router-dom";

const Sidebar = ({ menuItems, openFlyout, setOpenFlyout }) => {
  const history = useHistory();
  const SideMenu = menuItems.map(i => (
    <>
      <Link onClick={() => history.push(i.route)} text={i.name} />
      <Divider />
    </>
  ));
  return (
    <OverlayProvider zIndex={10000}>
      <Modal
        isOpen={openFlyout}
        size={4}
        type="flyout"
        side="left"
        srName="Side Menu"
        animate
        isClosable
        onCloseCb={() => {
          setOpenFlyout(false);
        }}
      >
        <div style={{ maxWidth: '80%', margin: 'auto', marginTop: '20px' }}>
          {SideMenu}
        </div>
      </Modal>
    </OverlayProvider>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.pages.menuItems,
});

export default connect(mapStateToProps)(Sidebar);
