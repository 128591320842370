/* eslint-disable no-param-reassign */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import settings from 'settings';
import {
  loggedIn,
  loginError,
  loggedInSuccessfully,
} from 'actions/authenticationActions';

const OauthCode = ({
  appConfig,
  location,
  history,
  dispatchloggedIn,
  dispatchloginError,
  dispatchLoggedInSuccessfully,
}) => {
  const [status, setStatus] = useState('idle');
  console.log(`Status=${status}`);

  useEffect(() => {
    if (appConfig) {
      if (status !== 'idle') return;
      setStatus('fetching');

      const params = new URLSearchParams(location.search);

      const code = params.get('code');
      const error = params.get('error');

      if (code) {
        const data = {
          code,
        };

        const { storeToken } = appConfig;
        fetch(
          `${settings.apiUrl}/coreapp/token?locale=${settings.locale}&envId=${
            window.REACT_APP_ENV_ID || settings.envId
          }&appId=${window.REACT_APP_APP_ID || settings.application}`,
          {
            method: 'post',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify(data),
            ...(storeToken === 'cookies' ? { credentials: 'include' } : {}),
            mode: 'cors',
          }
        )
          .then((ret) => {
            if (!ret.ok) {
              throw Error(ret.statusText);
            }
            return ret.json();
          })
          .then((json) => {
            const decoded = jwt.decode(json.id_token);
            json.name = decoded.name;
            json.family_name = decoded.family_name;
            json.given_name = decoded.given_name;
            json.email = decoded.email;
            json.ctry = decoded.ctry;

            if (storeToken === 'cookies') {
              delete json.access_token;
              delete json.id_token;
              delete json.refresh_token;
            }

            dispatchloggedIn(json);
            dispatchLoggedInSuccessfully();
            history.push(`/`);
            setStatus('fetched');
          })
          .catch((e) => {
            dispatchloginError({ error: e.message });
            history.push('/');
            setStatus('fetched');
          });
      } else if (error) {
        console.log('Got error');
        history.push('/');
      }
    }
  }, [
    appConfig,
    location,
    history,
    dispatchloggedIn,
    dispatchloginError,
    status,
  ]);
  return null;
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
  appConfig: state.config?.data,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchloggedIn: (data) => dispatch(loggedIn(data)),
  dispatchloginError: (data) => dispatch(loginError(data)),
  dispatchLoggedInSuccessfully: (data) => dispatch(loggedInSuccessfully()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OauthCode));