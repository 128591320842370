import { call, put, takeLatest, select, all, take } from 'redux-saga/effects';
import {
  CONFIG_REQUEST,
  CONFIG_REQUEST_FAIL,
  CONFIG_REQUEST_SUCCESS,
  SET_PAGE_CONFIG,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  setGlobalLoading
} from 'actions/configActions';

import { setError } from 'actions';
import {
  LOGGED_IN_SUCCESSFULLY,
  SET_IS_AUTH_APP
} from 'actions/authenticationActions';
import { isAuthSelector } from 'selectors/authSelector';
import configService from 'services/ConfigService';
import {
  LANGUAGE_REQUEST,
  LANGUAGE_LIST_REQUEST_SUCCESS,
  LANGUAGE_LIST_REQUEST_FAIL
} from 'actions/languageActions';

import TranslationService from 'services/TranslationService';

function* configWorker() {
  let newConfig;
  const configItemJSON = localStorage.getItem('config');
  const configItem = configItemJSON && JSON.parse(configItemJSON);
  if (
    configItem &&
    Date.now() < configItem.fetchDate + configItem.global.TTL * 1000
  ) {
    console.log('Fresh Config found, no call to backend');
    console.log(configItem);
    newConfig = configItem;
    yield put({
      type: CONFIG_REQUEST_SUCCESS,
      payload: configItem
    });
    if (Object.keys(configItem.pages).length) {
      yield put({
        type: SET_PAGE_CONFIG,
        payload: configItem.pages
      });
    }
  } else {
    console.log('Fresh Config NOT found, making call to backend');
    localStorage.removeItem('config');
    try {
      const data = yield call(configService.fetchConfig);
      newConfig = data;
      yield put({
        type: CONFIG_REQUEST_SUCCESS,
        payload: data
      });
      if (Object.keys(data.pages).length) {
        yield put({
          type: SET_PAGE_CONFIG,
          payload: data.pages
        });
      }
    } catch (error) {
      yield put({ type: CONFIG_REQUEST_FAIL, error });
      yield put(
        setError(error.response?.status || 500, { type: CONFIG_REQUEST })
      );
    }
  }
  try {
    const { data } = yield call(TranslationService.fetchLanguages);
    yield put({
      type: LANGUAGE_LIST_REQUEST_SUCCESS,
      payload: {
        languages: data
      }
    });
    const { defaultAppLanguage } = newConfig.global;
    const languagesState = yield select(state => state.language.languages);
    const languages = Object.keys(languagesState || {});
    const languageIndex = languages.findIndex(
      lang => defaultAppLanguage === lang
    );
    const language = languageIndex === -1 ? 'en' : defaultAppLanguage;

    if (language)
      yield put({
        type: LANGUAGE_REQUEST,
        payload: language
      });
  } catch (error) {
    yield put({ type: LANGUAGE_LIST_REQUEST_FAIL, error });
  }
}

function* configWatcher() {
  yield takeLatest(CONFIG_REQUEST, configWorker);
}

function* handleLoggedIn() {
  yield take(SET_IS_AUTH_APP);
  const isAuthApp = yield select(isAuthSelector);
  if (isAuthApp) {
    yield take(LOGGED_IN_SUCCESSFULLY);
  } else {
    yield take(CONFIG_REQUEST);
  }

  yield put(setGlobalLoading(true));
}

function* handleLoadingSuccess() {
  yield take(SET_IS_AUTH_APP);
  const isAuthApp = yield select(isAuthSelector);
  if (isAuthApp) {
    yield all([take(CONFIG_REQUEST_SUCCESS), take(GET_TOKEN_SUCCESS)]);
  } else {
    yield take(CONFIG_REQUEST_SUCCESS);
  }

  yield put(setGlobalLoading(false));
}

function* handleLoadingFailure() {
  while (true) {
    yield take([CONFIG_REQUEST_FAIL, GET_TOKEN_FAILURE]);
    yield put(setGlobalLoading(false));
  }
}

const configSaga = [
  handleLoggedIn(),
  handleLoadingSuccess(),
  handleLoadingFailure(),
  configWatcher()
];

export default configSaga;
