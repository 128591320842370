const CONFIG_REQUEST = 'CONFIG_REQUEST';
const CONFIG_REQUEST_SUCCESS = 'CONFIG_REQUEST_SUCCESS';
const CONFIG_REQUEST_FAIL = 'CONFIG_REQUEST_FAIL';
const SET_PAGE_CONFIG = 'SET_PAGE_CONFIG';
const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
const GLOBAL_LOADING = 'GLOBAL_LOADING';
const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

const fetchConfig = () => ({
  type: CONFIG_REQUEST
});

const setPageConfig = payload => ({
  type: SET_PAGE_CONFIG,
  payload
});
const setGlobalLoading = payload => ({ type: GLOBAL_LOADING, payload });

export {
  CONFIG_REQUEST,
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_FAIL,
  SET_PAGE_CONFIG,
  fetchConfig,
  setPageConfig,
  GLOBAL_LOADING,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  setGlobalLoading
};
