import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';
import AGENTLOGIN from 'agentlogin';
import AGENTLOGIN_pckg from '../../node_modules/agentlogin/package.json';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";

const AGENTLOGIN_reduxPerInstance = AGENTLOGIN_pckg['app-framework'] && AGENTLOGIN_pckg['app-framework'].reduxPerInstance;

const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};

const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(!reduxPerInstance) return null;
  return (state, action) => {
    if(action.type.startsWith(`@${pckg}/`) || state === undefined) {
      if(action.instanceId) {
        if(Array.isArray(action.instanceId) && action.instanceId.includes(instanceId)) {
          return reducer(state, action);
        } else if (action.instanceId === instanceId) {
          return reducer(state, action);
        } else {
          return state;
        }
      } else {
        return reducer(state, action);
      }
    } else {
      return state;
    }
  };
};

const singleReducersInit = {
  'agentlogin': singleReducer(AGENTLOGIN_reduxPerInstance, AGENTLOGIN.reducer),
};

const userReducersPerInstanceInit = {
  'agentlogin': AGENTLOGIN.reducer && AGENTLOGIN_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'f7a6f026_d65f_41d7_9f11_3260094824fc': perInstanceReducer('agentlogin', 'f7a6f026-d65f-41d7-9f11-3260094824fc', AGENTLOGIN_pckg['app-framework'], AGENTLOGIN.reducer),
  }).filter(pair => pair[1]))),
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});
