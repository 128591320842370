import React, { useEffect } from 'react';
import * as sec from '@vf-org/smapi-js';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Liveness from 'services/Liveness';
import { ThemeProvider } from 'styled-components';
import { themeWS10 } from 'r10-source-library';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import vfTheme from 'vf-comps/build/AppTheme';
import axios from 'axios';
import Cookies from 'js-cookie';
import GenericPages from './GenericPages';
import SharedPages from './sharedPages';
import LoadingIndicator from 'components/LoadingIndicator';
import { applicationMounted } from 'actions/applicationActions';
import { fetchConfig } from 'actions/configActions';
import { changeLanguage } from 'actions/languageActions';
import { persistor, store } from 'store/configureStore';
import AppRouter from 'routes/AppRouter';
import RefreshToken from './components/RefreshToken';
/*[Important Note] 
  please not that the blew line is for smapi testing to access sec from window object
  and it should be removed after smapi testing team finish the report   
*/
window.sec = sec;

const s3_hosting_path = require('./constants/s3.js');

Liveness.setStore(store);
store.dispatch(fetchConfig());

axios.interceptors.request.use(
  (req) => {
    const appConfig = localStorage.getItem('config');

    const { storeToken, global } = (appConfig && JSON.parse(appConfig)) || {};
    const { endpointSettings = [] } = global;

    const isAuthStoredInLocalStorage = storeToken == 'localstorage';
    const isAuthStoredInCookie = storeToken == 'cookies';

    // added to avoid Cors issue in the noAuth apps
    if (isAuthStoredInCookie) {
      req.withCredentials = true;
      req.headers['X-CSRF-TOKEN'] = Cookies.get('X-CSRF-TOKEN');
    }

    const settingBasedOnEndpointURl = endpointSettings?.find((elem) =>
      req.url?.toLowerCase().startsWith(elem.endpointUrl?.toLowerCase())
    );

    const settingBasedOnBaseURl = endpointSettings?.find((elem) =>
      req.url?.toLowerCase().startsWith(elem.baseUrl?.toLowerCase())
    );

    let settingObject = { headers: [] };

    if (settingBasedOnEndpointURl) {
      settingObject = settingBasedOnEndpointURl;
    } else if (settingBasedOnBaseURl) {
      settingObject = settingBasedOnBaseURl;
    }

    settingObject.headers.forEach((header) => {
      if (header && header != 'Authorization' && !req.headers[header]) {
        req.headers[header] = global[header];
      } else {
        if (header && header == 'Authorization' && isAuthStoredInLocalStorage) {
          const {
            agentTokenDetails: { token_type, access_token },
          } = JSON.parse(localStorage.getItem('auth'));
          const AuthorizationHeaderValue = `${token_type} ${access_token}`;
          req.headers[header] = AuthorizationHeaderValue;
        }
      }
    });

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO: revisit to review the objective of this interceptor (no duplication check)
axios.interceptors.request.use(
  (config) => {
    const appConfig = localStorage.getItem('config');
    const { storeToken } = (appConfig && JSON.parse(appConfig)) || {};
    if (storeToken === 'cookies') {
      config.withCredentials = true;
      config.headers['X-CSRF-TOKEN'] = Cookies.get('X-CSRF-TOKEN');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

themeWS10.setBaseAssetLocation(`${s3_hosting_path}/assets/ws10/`);
themeWS10.setAssetLocations('fonts', 'fonts/');
themeWS10.setAssetLocations('icons', 'icons/');
themeWS10.setAssetLocations('logos', 'logos/');
themeWS10.setAssetLocations('animations', 'animations/');

i18next
  .init({
    lng: 'en',
    resources: {},
  })
  .then((t) => {
    console.log('Languages initialized with no resources');
    store.dispatch(changeLanguage('en'));
  });

const App = () => {
  const smapiEnv = useSelector(
    (state) => state?.config?.data?.global?.smapiEnvironment
  );
  const smapiCookiesEnabled = useSelector(
    (state) => state?.config?.data?.global?.smapiCookiesEnabled
  );

  useEffect(() => {
    smapiInit();
  }, [smapiEnv, smapiCookiesEnabled]);

  useEffect(() => {
    store.dispatch(applicationMounted());
    smapiInit();
  }, []);

  const smapiInit = () => {
    sec.init({
      enabled: true,
      environment: smapiEnv ? smapiEnv : 'GCP_PRE',
      verbose: false,
      cookiesEnabled: smapiCookiesEnabled ? smapiCookiesEnabled : false,
      networkMonitoringEnabled: false,
      spaTrackingOptions: {
        enabled: true,
        replaceState: false,
      },
      headers: {
        'x-vf-trace-source': 'js:com.vodafone.lib.sec.smapiweb',
        'x-vf-trace-source-version': 'smapi-web-test',
      },
    });
  };
  return (
    <PersistGate loading={null} persistor={persistor}>
      <MUIThemeProvider theme={vfTheme}>
        <ThemeProvider theme={themeWS10}>
          <themeWS10.globalStyles />
          <div className="App">
            <RefreshToken axios={axios} />
            <LoadingIndicator />
            <GenericPages />
            <SharedPages />
            <AppRouter />
          </div>
        </ThemeProvider>
      </MUIThemeProvider>
    </PersistGate>
  );
};

export default App;