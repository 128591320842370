const LOGOUT = 'LOGOUT';
const LOGOUT_HANDLER = 'LOGOUT_HANDLER';
const INIT_LOGIN = 'INIT_LOGIN';
const LOGGED_IN = 'LOGGED_IN';
const LOGGED_IN_SUCCESSFULLY = 'LOGGED_IN_SUCCESSFULLY';
const LOGOUT_MESSAGE = 'LOGOUT_MESSAGE';
const SHOW_INACTIVITY_WARNING = 'SHOW_INACTIVITY_WARNING';
const HIDE_INACTIVITY_WARNING = 'HIDE_INACTIVITY_WARNING';
const CLEAR_LOGOUT_MESSAGE = 'CLEAR_LOGOUT_MESSAGE';
const LOGIN_ERROR = 'LOGIN_ERROR';
const SIGN_IN_PAGE_LOADED = 'SIGN_IN_PAGE_LOADED';
const SET_IS_AUTH_APP = 'SET_IS_AUTH_APP';
const SET_AGENT_DETAILS = 'SET_AGENT_DETAILS';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const UPDATE_TOKEN = 'UPDATE_TOKEN';

const loggedIn = payload => ({
  type: LOGGED_IN,
  payload
});

const loginError = error => ({
  type: LOGIN_ERROR,
  error
});

const loggedOut = () => ({
  type: LOGOUT
});
const loggedOutHandler = () => ({
  type: LOGOUT_HANDLER
});

const loggedInSuccessfully = () => ({
  type: LOGGED_IN_SUCCESSFULLY
});

const initLogin = () => ({
  type: INIT_LOGIN
});

const signInPageLoaded = () => ({
  type: SIGN_IN_PAGE_LOADED
});

const refreshToken = payload => ({
  type: REFRESH_TOKEN,
  payload
});

const updateToken = payload => ({
  type: UPDATE_TOKEN,
  payload
});

export {
  INIT_LOGIN,
  LOGGED_IN,
  LOGGED_IN_SUCCESSFULLY,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_HANDLER,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SHOW_INACTIVITY_WARNING,
  HIDE_INACTIVITY_WARNING,
  initLogin,
  loggedIn,
  loggedInSuccessfully,
  loginError,
  loggedOut,
  loggedOutHandler,
  signInPageLoaded,
  SET_IS_AUTH_APP,
  SET_AGENT_DETAILS,
  REFRESH_TOKEN,
  refreshToken,
  UPDATE_TOKEN,
  updateToken
};
