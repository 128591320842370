import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_HANDLER,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SHOW_INACTIVITY_WARNING,
  HIDE_INACTIVITY_WARNING,
  SET_IS_AUTH_APP,
  SET_AGENT_DETAILS
} from 'actions/authenticationActions';

import settings from 'settings';

const initialState = {};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case CLEAR_LOGOUT_MESSAGE:
    case LOGOUT: {
      localStorage.removeItem('auth');
      return {};
    }
    case LOGOUT_HANDLER: {
      window.location.href = `https://login.windows.net/${window.REACT_APP_TENANT_ID ||
        settings.tenantId}/oauth2/logout?post_logout_redirect_uri=${
        settings.authUri
      }&state=${window.location.origin}/logout`;
      return { ...initialState };
    }
    case LOGOUT_MESSAGE: {
      localStorage.removeItem('auth');
      return { error: payload };
    }
    case SHOW_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: true
      };
    }
    case HIDE_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: false
      };
    }
    case LOGIN_ERROR: {
      return error;
    }
    case LOGGED_IN: {
      localStorage.setItem('auth', JSON.stringify(action.payload));
      return payload;
    }
    case SET_AGENT_DETAILS: {
      const agentDetails = { ...state, agentTokenDetails: payload };
      localStorage.setItem('auth', JSON.stringify(agentDetails));
      return agentDetails;
    }
    case SET_IS_AUTH_APP:
      return { ...state, isAuthApp: payload };
    default:
      return state;
  }
};

export default reducer;
