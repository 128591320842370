/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Liveness from 'services/Liveness';
import { render } from 'pages/renderer';
import { store } from '../store/configureStore';
import { pageMounted, pageUnmounted } from '../actions/applicationActions';

const GenericTemplatedPage = ({ pageJson, pageTitle }) => {
  const rendered = pageJson ? render(pageJson) : null;
  useEffect(() => Liveness.update(), []);
  useEffect(() => {
    store.dispatch(pageMounted({ pageTitle }));
    return () => {
      store.dispatch(pageUnmounted({ pageTitle }));
    };
  }, []);
  console.log(rendered);
  return (
    <div
      onKeyDown={() => Liveness.update(false)}
      onTouchStart={() => Liveness.update(false)}
      onScrollCapture={() => Liveness.update(false)}
      onClick={() => Liveness.update(false)}
    >
      {rendered}
    </div>
  );
};

export default GenericTemplatedPage;
