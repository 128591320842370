import React, { useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { loggedOut } from "actions/authenticationActions";
import { apiUrl } from "../settings.json";
import Cookies from "js-cookie";
const Logout = ({ logout }) => {
  const removeCookies = async () => {
    await axios({
      method: "post",
      url: `${apiUrl}/coreapp/token/logout`,
    });
    Cookies.remove("X-CSRF-TOKEN");
  };

  useEffect(() => {
    const appConfig = localStorage.getItem("config");
    const { storeToken } = (appConfig && JSON.parse(appConfig)) || {};
    if (storeToken === "cookies") {
      // TODO: Need to recheck it again
      // removeCookies();
    }
    logout();
  });

  return <Redirect to="/" />;
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(loggedOut()),
});

export default connect(null, mapDispatchToProps)(Logout);